import React from 'react'

function Dropdownselect({ selected, cityChange }) {
  return (
    <div className="ddown-select-container">
      <select onChange={cityChange} name="cities" id="cities">
        <option value="" selected disabled hidden>Choose a city</option>
        <option selected={selected === 'berlin' ? true : false} value="berlin">Berlin</option>
        <option selected={selected === 'budapest' ? true : false} value="budapest">Budapest</option>
        <option selected={selected === 'johannesburg' ? true : false} value="johannesburg">Johannesburg</option>
        <option selected={selected === 'london' ? true : false} value="london">London</option>
        <option selected={selected === 'tokyo' ? true : false} value="tokyo">Tokyo</option>
        <option selected={selected === 'washington' ? true : false} value="washington">Washington</option>
      </select>
    </div>
  )
}

export default Dropdownselect
