import React from "react";
import { useState, useEffect } from "react";
import Dropdownselect from "./dropdownselect";

function Dropdown({ setWeather, get_WeatherIcon, weatherIcon  }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);
  const [city, setCity] = useState(null);
  const [cityName, setCityName] = useState(null);

  const cityChange = (e) => {
    setCityName(e.target.value);
    setCity(
      `https://api.openweathermap.org/data/2.5/forecast?q=${e.target.value}&units=metric&appid=5cd6cd9ead980508d34dd318313a8ef1`
    );
  };

  useEffect(() => {
    if (cityName) {
      setIsLoaded(false); //true értéket az isLoaded változónak/
      fetch(city)
        .then((response) => response.json())
        .then(
          (result) => {
            setIsLoaded(true); //true értéket az isLoaded változónak/
            setWeather(result); /*meghívja a másik által is használt setWeathert */
          },
          (error) => {
            setIsLoaded(true);
            setError(error);
          }
        );
    }
  }, [city]);

  if (error) {
    return <div>Error: {error.message}</div>; //ha error van visszatér error üzenettel/
  } else if (!isLoaded) {
    return <div></div>; //vagy visszatér loading üzenettel*/
  } else {

    return (
      <div className="ddown">
        <Dropdownselect selected={cityName} cityChange={cityChange} />
        <div className="ddown-content">
        </div>
      </div>
    );
  }
}

export default Dropdown;
