import React, { useState } from "react";
import "./App.css";
import Dropdown from "./components/dropdown";
import Weathercard from "./components/weathercard";

function App() {
  const [weather, setWeather] = useState({});

  const dateBuilder = (d) => {
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    let day = days[d.getDay()];
    let date = d.getDate();
    let month = months[d.getMonth()];
    let year = d.getFullYear();

    return `${day}, ${date} ${month} ${year}`

  }

  return (
    <div className="full-container">
      <div className="title-container">
        <div class="container">
          <div class="weather-side">
            <div class="weather-gradient"><h1 className="title"><span className="ar">AR</span><br></br>Weather</h1></div>
          </div>
        </div>
      </div>
      <div className="weather-set-container">
        <Dropdown
          setWeather={setWeather}
        ></Dropdown>
        <div className="date">{dateBuilder(new Date())}</div>
        {(typeof weather.list != "undefined") ? (
          <div className="location"><h1>{weather.city.name}</h1></div>) : (" ")}
      </div>
      <div className="main-container">
        <Weathercard
          weather={weather} />
      </div>
    </div>
  );
}

export default App;
