import React from 'react'

function Daily(props) {
  
    const { daily } = props;
    
    return (
        <div className="daily-container">
            <h3 className="daily-date">{daily.dt_txt.substring(0,10)}</h3>
            <h3 className="daily-time">{daily.dt_txt.substring(10,16)}</h3>
            <img alt="weather-icon" className="daily-icon" src={`http://openweathermap.org/img/wn/${daily.weather[0].icon}@4x.png`}></img>
            <h3 className="daily-temp">{Math.round(daily.main.temp)}°C</h3>
        </div>
      )
    }
    
    export default Daily;