import React from 'react'
import Daily from './daily';

function Weathercard({ weather }) {
  
  return (
    <div className="full-weather-container">
    <div className="weathercard-container">
      {(typeof weather.list != "undefined") ? (
        <>
          <div className="cityname-temp-container">
            <div className="temp">
              <img className="weather-icon" alt="clear" src={`http://openweathermap.org/img/wn/${weather.list[0].weather[0].icon}@4x.png`}></img>
              <h2>{Math.round(weather.list[0].main.temp)}°C<p className="sky-info">{weather.list[0].weather[0].description}</p></h2>
              
            </div>
          </div>
          <div className="extra-weather-datas">
            <h3 className="weather-data"><span className="weather-data-name">Feels like</span><br></br><strong>{Math.round(weather.list[0].main.feels_like)}°C</strong></h3>
            <h3 className="weather-data"><span className="weather-data-name">High</span><br></br><strong>{Math.round(weather.list[0].main.temp_max)}°C</strong></h3>
            <h3 className="weather-data"><span className="weather-data-name">Low</span><br></br><strong>{Math.round(weather.list[0].main.temp_min)}°C</strong></h3>
            <h3 className="weather-data"><span className="weather-data-name">Humidity</span><br></br><strong>{weather.list[0].main.humidity}%</strong></h3>
            <h3 className="weather-data"><span className="weather-data-name">Wind</span><br></br><strong>{weather.list[0].wind.speed} km/h</strong></h3>
            <h3 className="weather-data"><span className="weather-data-name">Pressure</span><br></br><strong>{weather.list[0].main.pressure} hPa</strong></h3>
          </div>
          
        </>
      ) : ('')}
    </div>
    {(typeof weather.list != "undefined") ? (
        <div className="main-daily-container">
    {weather.list.map((daily) => (<Daily key={daily.dt} daily={daily} />))} 
      </div>) : ('')}
      </div>
  )
}

export default Weathercard
